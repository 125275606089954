import React from 'react';
import { graphql } from 'gatsby';
import Markdown from 'react-markdown';

import CodeBlock from '../../components/CodeBlock';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import BlogPostPreview from './../../components/BlogPostPreview/BlogPostPreview';
import blogPostStyles from './blog-post.module.scss';
import { getDate } from './../../common/utils';

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      frontmatter {
        title
        image
        content
        author
        category
        date
        description
        keywords
      }
      fields {
        slug
      }
    }
    allMarkdownRemark(filter: {fields: {collectionType: {eq: "blog"}, slug: {ne: $slug}}}, limit: 3, sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          frontmatter {
            category
            image
            title
            category
            description
            date
            keywords
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

const BlogPost = ({ data }) => {
  const keywords = data.markdownRemark.frontmatter.keywords[0].split(' ');
  const url = `https://techpods.co/blog/${data.markdownRemark.fields.slug}`;

  const moreArticles = data.allMarkdownRemark.edges.map(article => {
    return (
      <BlogPostPreview
        data={article.node.frontmatter}
        slug={article.node.fields.slug}
        key={article.node.frontmatter.title}
      />
    );
  });

  return (
    <Layout>
      <Seo 
        title={`${data.markdownRemark.frontmatter.title} | TechPods`}
        keywords={keywords}
        description={data.markdownRemark.frontmatter.description}
        image={data.markdownRemark.frontmatter.image}
        url={url}
      />
      <main>
        <article className={blogPostStyles.article}>
          <span className={blogPostStyles.category}>{`${data.markdownRemark.frontmatter.category} | ${data.markdownRemark.frontmatter.author}`}</span>
          <h1>{data.markdownRemark.frontmatter.title}</h1>
          <div className={blogPostStyles.articleAbout}>
            <span className={blogPostStyles.articleDate}>{getDate(data.markdownRemark.frontmatter.date)}</span>
            <div>
              <a href={`https://twitter.com/intent/tweet/?url=${url}`} target="_blank" rel="noreferrer" aria-label="Share on Twitter">
                <svg id="twitter" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#blog-post-twitter)">
                    <path d="M28 5.31825C26.9587 5.775 25.8492 6.07775 24.6925 6.22475C25.8825 5.51425 26.7908 4.39775 27.2178 3.052C26.1083 3.7135 24.8832 4.18075 23.5777 4.4415C22.5243 3.31975 21.0227 2.625 19.3848 2.625C16.2067 2.625 13.6482 5.2045 13.6482 8.36675C13.6482 8.82175 13.6868 9.25925 13.7812 9.67575C9.009 9.443 4.78625 7.15575 1.9495 3.6715C1.45425 4.53075 1.16375 5.51425 1.16375 6.573C1.16375 8.561 2.1875 10.3232 3.7135 11.3435C2.79125 11.326 1.8865 11.0583 1.12 10.6365C1.12 10.654 1.12 10.6768 1.12 10.6995C1.12 13.489 3.10975 15.806 5.719 16.3398C5.25175 16.4675 4.7425 16.5287 4.214 16.5287C3.8465 16.5287 3.4755 16.5077 3.12725 16.4307C3.871 18.704 5.9815 20.3752 8.491 20.4295C6.538 21.9573 4.05825 22.8778 1.37375 22.8778C0.903 22.8778 0.4515 22.8568 0 22.799C2.54275 24.4387 5.55625 25.375 8.806 25.375C19.369 25.375 25.144 16.625 25.144 9.0405C25.144 8.78675 25.1353 8.54175 25.123 8.2985C26.2622 7.49 27.2195 6.48025 28 5.31825Z" fill="#7192B1"/>
                  </g>
                  <defs>
                    <clipPath id="blog-post-twitter">
                      <rect width="28" height="28" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`} target="_blank" rel="noreferrer" aria-label="Share on LinkedIn">
                <svg id="linkedin" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.04468 1.42249C2.11983 1.42249 0.861328 2.68644 0.861328 4.34773C0.861328 5.97233 2.08234 7.27234 3.97082 7.27234H4.00735C5.96986 7.27234 7.19119 5.97233 7.19119 4.34773C7.1545 2.68644 5.96986 1.42249 4.04468 1.42249Z" fill="#7192B1"/>
                  <path d="M1.19336 9.58282H6.82051V26.5123H1.19336V9.58282Z" fill="#7192B1"/>
                  <path d="M20.6341 9.18518C17.5984 9.18518 15.5629 12.0377 15.5629 12.0377V9.58252H9.93555V26.5121H15.5625V17.058C15.5625 16.5518 15.5992 16.0465 15.7479 15.6846C16.1547 14.6739 17.0804 13.6269 18.635 13.6269C20.6712 13.6269 21.4856 15.1794 21.4856 17.4553V26.5121H27.1123V16.805C27.1123 11.6049 24.336 9.18518 20.6341 9.18518Z" fill="#7192B1"/>
                </svg>
              </a>
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} rel="noreferrer" target="_blank"  aria-label="Share on Facebook">
                <svg id="facebook" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.75 9.625V6.125C15.75 5.159 16.534 4.375 17.5 4.375H19.25V0H15.75C12.8503 0 10.5 2.35025 10.5 5.25V9.625H7V14H10.5V28H15.75V14H19.25L21 9.625H15.75Z" fill="#7192B1"/>
                </svg>
              </a>
            </div>
          </div>
          <img
            className={blogPostStyles.articleImage}
            src={data.markdownRemark.frontmatter.image}
            alt={data.markdownRemark.frontmatter.title}
          />
          <div>
            <Markdown
              source={data.markdownRemark.frontmatter.content} 
              renderers={{ code: CodeBlock }}
              className={blogPostStyles.content}
            ></Markdown>
          </div>
        </article>
        <section className={blogPostStyles.moreArticles}>
          <h2 className="secondary-title">More articles</h2>
          <hr />
          <div className={blogPostStyles.moreArticlesGrid }>
            {moreArticles}
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default BlogPost;